enum HttpCodes {
  Success = 200,
  NoContent = 204,

  BadRequest = 400,
  Unauthorized = 401,
  Forbidden = 403,
  NotFound = 404,
  UnprocessableEntity = 422,

  // eslint-disable-next-line @typescript-eslint/no-duplicate-enum-values
  Error4xx = 400,
  Error5xx = 500,
}

enum ErrorCodes {
  Captcha = "CaptchaError",
  CardMergeBlocked = "cardMergeBlocked",
  IncompleteProfile = "incompleteProfile",
  EmailForbidden = "emailForbiddenToUse",
  ValidationError = "validationError",
}

/**
 * @swagger web-gateway: https://web-gateway.dev.ya.magnit.ru/docs/index.html
 * @swagger omni-web: https://omni-web.k8s.dev.mos.corp/docs#
 * @swagger magnit-id: https://id.dev.ya.magnit.ru/docs/index.html
 */
const urls = {
  "otp": {
    /**
     * Генерация и отправка OTP кода по номеру телефона
     * @link: https://id.dev.ya.magnit.ru/docs/index.html#/authorization/MagnitIDSendOTPV1
     */
    getCode: "/v1/auth/otp",
    /**
     * Проверка OTP и получение MagnitID Code кода для авторизации нового пользователя
     * @link: hhttps://id.dev.ya.magnit.ru/docs/index.html#/authorization/MagnitIDCheckOTPV1
     */
    checkCode: "/v1/auth/otp/check",
  },

  "auth": {
    /**
     * Авторизация
     * @link: https://id.dev.ya.magnit.ru/docs/index.html#/authorization/MagnitIDGenerateTokenV1
     */
    login: "/v1/auth/token",
    /**
     * Обновление JWT токена
     * @link: https://id.dev.ya.magnit.ru/docs/index.html#/authorization/MagnitIDRefreshTokenV1
     */
    refresh: "/v1/auth/token/refresh",
  },

  "user": {
    /**
     * Получаем карточки пользователя
     * @link: https://omni-web.k8s.dev.mos.corp/docs#/cards-v2/cardsV2GetV3UserCards
     */
    cards: "/v3/user/cards",
    /**
     * Смерживаем виртуальную и физическую карты
     * @link: https://omni-web.k8s.dev.mos.corp/docs#/cards-v2/cardsV2PostV2UserCardMerge
     */
    cardMerge: "/v2/user/card/merge",
    /**
     * Подтверждение e-mail
     * @link: https://id.dev.ya.magnit.ru/docs/index.html#/profiles/MagnitIDEmailConfirmV1
     */
    emailConfirmation: "/v1/profile/email-confirm",
    /**
     * Регистрация нового пользователя
     * @link: https://id.dev.ya.magnit.ru/docs/index.html#/profiles/MagnitIDRegisterProfileByMagnitIDCodeV1
     */
    register: "/v3/profile/register/magnit-id-code",
    /**
     * Получаем профиль пользователя или обноваляем (patch)
     * https://id.dev.ya.magnit.ru/docs/index.html#/profiles/MagnitIDGetProfileV3
     */
    profile: "/v1/profile",
    /**
     * Получение/изменение разрешений пользователя на коммуникации
     * @swagger https://omni-web.k8s.dev.mos.corp/docs#/cuscom-v2/cuscomV2GetCuscomV2UserPermissions
     */
    permissions: "/cuscom/v2/user/permissions",
  },

  "promo": {
    /**
     * Получаем список промо-категорий
     * https://omni-web.k8s.dev.mos.corp/docs#/promocacher/promocacherGetV2PromotionsCategories
     */
    categories: "/v2/promotions/categories",
    /**
     * Получаем список промо-товаров по категории
     * @link: https://omni-web.k8s.dev.mos.corp/docs#/promocacher/promocacherGetV1Promotions
     */
    products: "/v1/promotions",
    /**
     * Получаем информации о промо-акции
     * @link: https://omni-web.k8s.dev.mos.corp/docs#/promocacher/promocacherGetV2PromotionsItem
     */
    item: "/v2/promotions/item",
    /**
     * Получаем товаров по типу акции
     * @link: https://omni-web.k8s.dev.mos.corp/docs#/promocacher/promocacherGetV1PromotionsType
     */
    type: "/v1/promotions/type",

    /**
     * Полнотекстовый поиск промоакций
     * @link: https://omni-web.k8s.dev.mos.corp/docs#/promosearcher/promosearcherGetV1PromotionsSearch
     */
    search: "/v1/promotions/search",
  },

  // Взаимодействие с промолоадером. Данные получаем из сервиса promoloader-api.
  "promoloader": {
    /** Акционные подборки /v1/promos/mp/{shopCode}/{promoId}
     * @link https://omni-web.k8s.dev.mos.corp/docs#/promoloader-api/promoloaderApiGetV1PromosMpByShopCodeByPromoId
     */
    selections: "/v1/promos/mp",
  },

  "goods": {
    /**
     * Поиск товаров по категориям или по поисковой строке (обновленный контракт с поддержкой Маркета)
     * @link: https://omni-web.k8s.dev.mos.corp/docs#/catalog-bff/catalogBffPostV2GoodsSearch
     */
    products: "/v2/goods/search",
    /**
     * Получение дерева категорий.
     * @link: https://omni-web.k8s.dev.mos.corp/docs#/catalog-bff/catalogBffGetV3CategoriesStoreByStoreCode
     */
    categories: "/v3/categories/store",
    /**
     * Получаем информации о товаре
     * @link: https://omni-web.k8s.dev.mos.corp/docs#/catalog-bff/catalogBffGetV2GoodsByGoodIdStoresByStoreCode
     */
    product: "/v2/goods/",
    /**
     * Получение фильтров для товарного каталога
     * @link: https://omni-web.k8s.dev.mos.corp/docs#/catalog-bff/catalogBffPostV1GoodsFilters
     */
    filters: "/v1/goods/filters",
  },

  "userReviews": {
    /**
     * Список оценок объекта
     * @link: http://omni-web.k8s.dev.mos.corp/docs#/user-reviews/userReviewsGetV1ObjectByObjectIdScores
     */
    objectScores: (id: string) => `/v1/object/${id}/scores`,
  },

  "content": {
    /**
     * Контент главных слайдов для главной страницы
     * https://omni-web.k8s.dev.mos.corp/docs#/configurator-api/configuratorApiGetV1CosmeticMagnitRuMainPageAdfox
     */
    mainSlides: "/v1/cosmetic-magnit-ru/main-page/adfox",
    /**
     * Метод получения "Бегущей строки"
     * https://omni-web.k8s.dev.mos.corp/docs#/configurator-api/configuratorApiGetV1CosmeticMagnitRuMainPageTicker
     */
    ticker: "/v1/cosmetic-magnit-ru/main-page/ticker",
    /**
     * Метод получения слайдов в разделе "Бренды"
     * https://omni-web.k8s.dev.mos.corp/docs#/configurator-api/configuratorApiGetV1CosmeticMagnitRuMainPageBrands
     */
    brands: "/v1/cosmetic-magnit-ru/main-page/brands",
    /**
     * Метод получения слайдов в разделе "Подарочные наборы"
     * https://omni-web.k8s.dev.mos.corp/docs#/configurator-api/configuratorApiGetV1CosmeticMagnitRuMainPageGiftPackages
     */
    giftPackages: "/v1/cosmetic-magnit-ru/main-page/gift-packages",
    /**
     * Метод получения категорий для раздела "Каталог" на главной странице
     * https://omni-web.k8s.dev.mos.corp/docs#/configurator-api/configuratorApiGetV1CosmeticMagnitRuMainPageCategories
     */
    categories: "/v1/cosmetic-magnit-ru/main-page/categories",
    /**
     * Метод получения слайдов в разделе "Специальные предложения"
     * https://omni-web.k8s.dev.mos.corp/docs#/configurator-api/configuratorApiGetV1CosmeticMagnitRuMainPageSpecialOffers
     */
    specialOffers: "/v1/cosmetic-magnit-ru/main-page/special-offers",
    /**
     * Метод получения слайдов в разделе "Эксклюзивно у нас"
     * https://omni-web.k8s.dev.mos.corp/docs#/configurator-api/configuratorApiGetV1CosmeticMagnitRuMainPageExclusiveWithUs
     */
    exclusiveWithUs: "/v1/cosmetic-magnit-ru/main-page/exclusive-with-us",
    /**
     * Метод получения секции "Новинки"
     * https://omni-web.k8s.dev.mos.corp/docs#/configurator-api/configuratorApiGetV1CosmeticMagnitRuMainPageNewArrival
     */
    newArrival: "/v1/cosmetic-magnit-ru/main-page/new-arrival",
    /**
     * Данные для страницы промо-каталога
     * @link: https://omni-web.k8s.dev.mos.corp/docs#/configurator-api/configuratorApiGetV1CosmeticMagnitRuCatalogPromosAndDiscounts
     */
    catalogPromosDiscounts: "/v1/cosmetic-magnit-ru/catalog/promos-and-discounts",
    /**
     * Получение статьи по ID.
     * https://omni-web.k8s.dev.mos.corp/docs#/configurator-api/configuratorApiGetV1CosmeticMagnitRuMainPageArticlesByArticleId
     */
    article: "/v1/cosmetic-magnit-ru/main-page/articles",
    /**
     * Получение публикаций.
     * https://omni-web.k8s.dev.mos.corp/docs#/configurator-api/configuratorApiPostV1CosmeticMagnitRuMainPagePublications
     */
    publications: "/v1/cosmetic-magnit-ru/main-page/publications",
    /**
     * Получение брендированных товарных категорий.
     * https://omni-web.k8s.dev.mos.corp/docs#/configurator-api/configuratorApiGetV1CosmeticMagnitRuCatalogBrandedGoodsCategories
     */
    brandedGoodsCategories: "/v1/cosmetic-magnit-ru/catalog/branded-goods-categories",
  },

  "store-search": {
    /** Получение списка магазинов по поисковой строке.
     * @link https://omni-web.k8s.dev.mos.corp/docs#/store-search/storeSearchPostV1StoreSearchAddress
     */
    address: "/v1/store-search/address",
    /** Получение списка магазинов по геолокации.
     * @link https://omni-web.k8s.dev.mos.corp/docs#/store-search/storeSearchPostV1StoreSearchGeo
     */
    geo: "/v1/store-search/geo",
    /** Получение магазина по его коду.
     * @link https://omni-web.k8s.dev.mos.corp/docs#/store-search/storeSearchGetV1StoreSearchByStoreCode
     */
    store: "/v1/store-search",
  },
};

export { urls, HttpCodes, ErrorCodes };

import payload_plugin_BNPKVw66obyvXK_hHapqTHFTsNu_iOJBct_9HiKmR5k from "/app/node_modules/@pinia/nuxt/dist/runtime/payload-plugin.js";
import revive_payload_client_lyWX1wvL7rSItZC0SHHXlpXViCrvKJ1yqCDTSoqslF0 from "/app/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_PtamfB47yqQY_Rh4zjrimgYJkXOrkZ_s7Rhm1JWaAcQ from "/app/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_8hDy86_j6V8TqNyl7F4bftmUgTOJ_p_KIyB_zFbEOAI from "/app/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import _0_siteConfig_E2CmUpC0QPEIjdkp_3WpSPBzcFpkIbxeGjimKojgshY from "/app/node_modules/nuxt-site-config/dist/runtime/app/plugins/0.siteConfig.js";
import payload_client_X0_XABaJEN3mXNpLz5RSfZdoLq_diKzZIWPF4HinC5Q from "/app/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_ZJ5GCYdi5wuazfUSPOwEPRbbU_oPgLFgZbKGOei_dc0 from "/app/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_kRdP0hsyNSWRJESGlaqVgRQ1Bgm0NpNaCfqmEeSNd0I from "/app/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_x05C_tQGwZj13_VNB3Soz0TV8ALoiwkuOXzHHEW9mKk from "/app/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_9XdWcl3TKzrg9MGaF1HsLanask1MPj0NDHZ6tUMduE0 from "/app/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_z4hgvsiddfKkfXTP6M8M4zG5Cb7sGnDhcryKVM45Di4 from "/app/src/.nuxt/components.plugin.mjs";
import prefetch_client_xwHiCvtnqAuv25cPdV3VTRqlYkBwUqHZbymrolAkzB8 from "/app/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import sentry_client_rwkQ4rLVgm42JB7E9bzCzDQeRqW5Z_5NZn6cUj3j_Mk from "/app/node_modules/@sentry/nuxt/build/module/runtime/plugins/sentry.client.js";
import sentry_client_config_jWSZGY3AZiojeM3FtOet57G_x4XiATyA4Ng46mi7Tag from "/app/src/.nuxt/sentry-client-config.mjs";
import fingerprint_CoCda3w5Soas1_cgcm0njZ0Xr0FG33mASBa12ndy7oI from "/app/node_modules/@magnit/layer-api/plugins/fingerprint.ts";
import transport_8lvbPty5TECeiM6_rXzJFkV7FM8kTKM7SujDoADBN68 from "/app/node_modules/@magnit/layer-api/plugins/transport.ts";
import analytic_CS5c1MOp6KU3KGtoadb_xCvIAQ5kPtZsQIiV5IEegb4 from "/app/src/plugins/analytic.ts";
import error_crtoTsZOJYpzsfZUQLO_Q8nvEvH92fJFjM1mhY4LidY from "/app/src/plugins/error.ts";
import fingerprint_Z88FedfufQtzrTpNf_fgFU0eLEH58IjKQl6xlNHJHdk from "/app/src/plugins/fingerprint.ts";
import logger_ggJJ1b_gAJTrf4jwGvw2HfXxl47iYX7oRqJjDvWcATM from "/app/src/plugins/logger.ts";
import piniaReset_bR6H1JWYaB_8nk1mtRpVCpZKmcL2u77K3yM6sYmuYuo from "/app/src/plugins/piniaReset.ts";
import toast_F_pfj7FgRLbXLAWiXjuGtvyN_yqw93f_EjHy5PiyXpc from "/app/src/plugins/toast.ts";
import v_mask_436SCALGvZs11Kc6FFs478cp9oxPADaN9NCHdsOdNsY from "/app/src/plugins/v-mask.ts";
export default [
  payload_plugin_BNPKVw66obyvXK_hHapqTHFTsNu_iOJBct_9HiKmR5k,
  revive_payload_client_lyWX1wvL7rSItZC0SHHXlpXViCrvKJ1yqCDTSoqslF0,
  unhead_PtamfB47yqQY_Rh4zjrimgYJkXOrkZ_s7Rhm1JWaAcQ,
  router_8hDy86_j6V8TqNyl7F4bftmUgTOJ_p_KIyB_zFbEOAI,
  _0_siteConfig_E2CmUpC0QPEIjdkp_3WpSPBzcFpkIbxeGjimKojgshY,
  payload_client_X0_XABaJEN3mXNpLz5RSfZdoLq_diKzZIWPF4HinC5Q,
  navigation_repaint_client_ZJ5GCYdi5wuazfUSPOwEPRbbU_oPgLFgZbKGOei_dc0,
  check_outdated_build_client_kRdP0hsyNSWRJESGlaqVgRQ1Bgm0NpNaCfqmEeSNd0I,
  chunk_reload_client_x05C_tQGwZj13_VNB3Soz0TV8ALoiwkuOXzHHEW9mKk,
  plugin_vue3_9XdWcl3TKzrg9MGaF1HsLanask1MPj0NDHZ6tUMduE0,
  components_plugin_z4hgvsiddfKkfXTP6M8M4zG5Cb7sGnDhcryKVM45Di4,
  prefetch_client_xwHiCvtnqAuv25cPdV3VTRqlYkBwUqHZbymrolAkzB8,
  sentry_client_rwkQ4rLVgm42JB7E9bzCzDQeRqW5Z_5NZn6cUj3j_Mk,
  sentry_client_config_jWSZGY3AZiojeM3FtOet57G_x4XiATyA4Ng46mi7Tag,
  fingerprint_CoCda3w5Soas1_cgcm0njZ0Xr0FG33mASBa12ndy7oI,
  transport_8lvbPty5TECeiM6_rXzJFkV7FM8kTKM7SujDoADBN68,
  analytic_CS5c1MOp6KU3KGtoadb_xCvIAQ5kPtZsQIiV5IEegb4,
  error_crtoTsZOJYpzsfZUQLO_Q8nvEvH92fJFjM1mhY4LidY,
  fingerprint_Z88FedfufQtzrTpNf_fgFU0eLEH58IjKQl6xlNHJHdk,
  logger_ggJJ1b_gAJTrf4jwGvw2HfXxl47iYX7oRqJjDvWcATM,
  piniaReset_bR6H1JWYaB_8nk1mtRpVCpZKmcL2u77K3yM6sYmuYuo,
  toast_F_pfj7FgRLbXLAWiXjuGtvyN_yqw93f_EjHy5PiyXpc,
  v_mask_436SCALGvZs11Kc6FFs478cp9oxPADaN9NCHdsOdNsY
]
import * as Sentry from "@sentry/nuxt";

export default defineNuxtRouteMiddleware(() => {
  /**
   *  ToDo: Данное решение не работает полноценно из-за не до конца проработанного флоу работы с профилем
   *  А именно, не отрабатывает при первом заходе, т.к. в store еще не хранится пользак
   *  Нужно реализовать глобальный middleware авторизации/получения профиля, который при наличии куки авторизации будет:
   *   - получать профиль
   *   - если необходимо - обновлять токен
   *   - если токен кривой - удалять куку с ним (разлогинивать)
   */

  const userStore = useUserStore();
  if (userStore.profile?.userId) {
    Sentry.setUser({
      id: userStore.profile.userId,
      email: userStore.profile.email,
    });
  } else {
    Sentry.setUser(null);
  }
});

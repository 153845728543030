
if (import.meta.hot) {
  import.meta.hot.accept((mod) => {
    const router = import.meta.hot.data.router
    const generateRoutes = import.meta.hot.data.generateRoutes
    if (!router || !generateRoutes) {
      import.meta.hot.invalidate('[nuxt] Cannot replace routes because there is no active router. Reloading.')
      return
    }
    router.clearRoutes()
    const routes = generateRoutes(mod.default || mod)
    function addRoutes (routes) {
      for (const route of routes) {
        router.addRoute(route)
      }
      router.replace(router.currentRoute.value.fullPath)
    }
    if (routes && 'then' in routes) {
      routes.then(addRoutes)
    } else {
      addRoutes(routes)
    }
  })
}

export function handleHotUpdate(_router, _generateRoutes) {
  if (import.meta.hot) {
    import.meta.hot.data ||= {}
    import.meta.hot.data.router = _router
    import.meta.hot.data.generateRoutes = _generateRoutes
  }
}
import { default as indexiNtA_znKj_zn_45wsEgsmLpyYSVS7XVZ24_KIDRjqK_10Meta } from "/app/src/pages/index.vue?macro=true";
import { default as index6tHTO9yyZ6W36lAgVCJDAwqZfZXxFDLQ6LwumzZ7mxkMeta } from "/app/src/pages/search/index.vue?macro=true";
import { default as cookie_45policyJGFq3o4u1xTPQn_0GB7HpO9BWnIj8wkgjhzm5cVcyD8Meta } from "/app/src/pages/cookie-policy.vue?macro=true";
import { default as indexNYWK0GyW6dYkxzc_Xw2jPsDEQJ_5DePgGw0OOfQgtVEMeta } from "/app/src/pages/profile/index.vue?macro=true";
import { default as index6xlRuVnAmXTWv4FZ4u2hcCzbnBcDBpuZxBaILzbDsGcMeta } from "/app/src/pages/articles/index.vue?macro=true";
import { default as bonusesigfp8mr6vpL5YWXvG5u27P6YTNdtlXsqod8a3JmAk_MMeta } from "/app/src/pages/profile/bonuses.vue?macro=true";
import { default as stickersmjdiXGoRhArONnGZouJWhvkCC7clO_452eFR6WEI0STfsMeta } from "/app/src/pages/profile/stickers.vue?macro=true";
import { default as cardFwumKn9PnE4jy8_45AgiXSAbuoVYZ9UjaKvdZbubPLIUwMeta } from "/app/src/pages/registration/card.vue?macro=true";
import { default as incompleteGk7o3MDELS3wcZK2W9DloZ9ojFMCsboELoQrjDvgmOwMeta } from "/app/src/pages/profile/incomplete.vue?macro=true";
import { default as indexaXET_C8ge14dK_Ls0DxrDFrMs7dvNMxSxP8SayQloxUMeta } from "/app/src/pages/registration/index.vue?macro=true";
import { default as _91id_93_45_91slug_936DmASyCImh30EJ84MLQbLQf7jwnKnpbiq92thuT7VecMeta } from "/app/src/pages/popular/[id]-[slug].vue?macro=true";
import { default as _91id_93_45_91slug_93ejve6LSCieC65WjEynFnkX551E2JbPTSTFJWMpiIxVoMeta } from "/app/src/pages/articles/[id]-[slug].vue?macro=true";
import { default as reviewsySnbECH5C_45G3hpD9seTrRTwAWwi1fBqoNP7660LBYEUMeta } from "/app/src/pages/product/[product]-[slug]/reviews.vue?macro=true";
import { default as _91product_93_45_91slug_93AkHjBkDKf4wR1RRN_45olOV2gg_9FO0QELAdMbM_45B8G_45cMeta } from "/app/src/pages/product/[product]-[slug].vue?macro=true";
import { default as _91category_93_45_91slug_93ZxRHWiYMBWSLdNwx_YU4Mma_7hL4cDIdGOBa32WMOcEMeta } from "/app/src/pages/catalog/[category]-[slug].vue?macro=true";
import { default as _91product_93_45_91slug_931cPZelwGOGUHUz3bZ0b3u0yGJ3Mt2svJCOMQj7Ko9wAMeta } from "/app/src/pages/promo-product/[product]-[slug].vue?macro=true";
import { default as _91category_93_45_91categorySlug_93uCiDVu_ELOsc9MYDRx6v_9nVGsz_45N67MSQp0Rd_452rvMMeta } from "/app/src/pages/promo-catalog/[category]-[categorySlug].vue?macro=true";
import { default as component_45stubbjLkcGu196H3H6U3AGpzju91fOJPpk9xx3zVsuzjgIAMeta } from "/app/node_modules/nuxt/dist/pages/runtime/component-stub.js?macro=true";
import { default as component_45stubbjLkcGu196H3H6U3AGpzju91fOJPpk9xx3zVsuzjgIA } from "/app/node_modules/nuxt/dist/pages/runtime/component-stub.js";
export default [
  {
    name: "index",
    path: "/",
    meta: indexiNtA_znKj_zn_45wsEgsmLpyYSVS7XVZ24_KIDRjqK_10Meta || {},
    component: () => import("/app/src/pages/index.vue")
  },
  {
    name: "search",
    path: "/search",
    component: () => import("/app/src/pages/search/index.vue")
  },
  {
    name: "cookie-policy",
    path: "/cookie-policy",
    component: () => import("/app/src/pages/cookie-policy.vue")
  },
  {
    name: "profile",
    path: "/profile",
    component: () => import("/app/src/pages/profile/index.vue")
  },
  {
    name: "articles",
    path: "/articles",
    component: () => import("/app/src/pages/articles/index.vue")
  },
  {
    name: "profile-bonuses",
    path: "/profile/bonuses",
    component: () => import("/app/src/pages/profile/bonuses.vue")
  },
  {
    name: "profile-stickers",
    path: "/profile/stickers",
    component: () => import("/app/src/pages/profile/stickers.vue")
  },
  {
    name: "registration-card",
    path: "/registration/card",
    meta: cardFwumKn9PnE4jy8_45AgiXSAbuoVYZ9UjaKvdZbubPLIUwMeta || {},
    component: () => import("/app/src/pages/registration/card.vue")
  },
  {
    name: "profile-incomplete",
    path: "/profile/incomplete",
    meta: incompleteGk7o3MDELS3wcZK2W9DloZ9ojFMCsboELoQrjDvgmOwMeta || {},
    component: () => import("/app/src/pages/profile/incomplete.vue")
  },
  {
    name: "registration",
    path: "/registration",
    meta: indexaXET_C8ge14dK_Ls0DxrDFrMs7dvNMxSxP8SayQloxUMeta || {},
    component: () => import("/app/src/pages/registration/index.vue")
  },
  {
    name: "popular-id-slug",
    path: "/popular/:id()-:slug()",
    component: () => import("/app/src/pages/popular/[id]-[slug].vue")
  },
  {
    name: "articles-id-slug",
    path: "/articles/:id()-:slug()",
    component: () => import("/app/src/pages/articles/[id]-[slug].vue")
  },
  {
    name: "product-product-slug",
    path: "/product/:product()-:slug()",
    meta: _91product_93_45_91slug_93AkHjBkDKf4wR1RRN_45olOV2gg_9FO0QELAdMbM_45B8G_45cMeta || {},
    component: () => import("/app/src/pages/product/[product]-[slug].vue"),
    children: [
  {
    name: "product-product-slug-reviews",
    path: "reviews",
    component: () => import("/app/src/pages/product/[product]-[slug]/reviews.vue")
  }
]
  },
  {
    name: "catalog-category-slug",
    path: "/catalog/:category()-:slug()",
    component: () => import("/app/src/pages/catalog/[category]-[slug].vue")
  },
  {
    name: "promo-product-product-slug",
    path: "/promo-product/:product()-:slug()",
    meta: _91product_93_45_91slug_931cPZelwGOGUHUz3bZ0b3u0yGJ3Mt2svJCOMQj7Ko9wAMeta || {},
    component: () => import("/app/src/pages/promo-product/[product]-[slug].vue")
  },
  {
    name: "promo-catalog-category-categorySlug",
    path: "/promo-catalog/:category()-:categorySlug()",
    component: () => import("/app/src/pages/promo-catalog/[category]-[categorySlug].vue")
  },
  {
    name: component_45stubbjLkcGu196H3H6U3AGpzju91fOJPpk9xx3zVsuzjgIAMeta?.name,
    path: "/sitemap.xml",
    component: component_45stubbjLkcGu196H3H6U3AGpzju91fOJPpk9xx3zVsuzjgIA
  }
]